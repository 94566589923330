import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Image from "components/Image";
import LinkInternalBig from "../components/LinkInternalBig";

export const query = graphql`
  {
    wp {
      page(id: "/group/", idType: URI) {
        id
        title
        slug
        groupPage {
          heading
          introPanel {
            heading
            copy
          }
          sections {
            image {
              ...MediaItem
            }
            title
            heading
            copy
            link {
              ... on WP_Page {
                id
                uri
              }
            }
          }
        }
      }
    }
  }
`;

const group = ({ data }) => {
  const { page } = data.wp;
  const {
    groupPage: { heading, introPanel, sections },
  } = data.wp.page;

  return (
    <Layout title={page.title} logoLink={`/`}>
      <main>
        <div className="pt-16 bg-three-quarter-tone from-tiber to-white">
          <header className="py-20 text-white">
            <h2 className="text-3xl md:text-5xl text-center font-bold uppercase">
              {heading}
            </h2>
          </header>
          <div className="md:container mx-6 md:mx-auto bg-white text-firefly text-center py-10 mb-auto">
            <div className="mx-auto max-w-[960px] px-8">
              <div className="py-8 space-y-8">
                {introPanel.heading && (
                  <div
                    className="text-xl md:text-3xl font-bold uppercase tracking-wider"
                    dangerouslySetInnerHTML={{ __html: introPanel.heading }}
                  ></div>
                )}
                {introPanel.copy && (
                  <div
                    className="text-stormdust tracking-wider space-y-4 font-light"
                    dangerouslySetInnerHTML={{ __html: introPanel.copy }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-firefly/0">
          <div className="md:container mx-6 md:mx-auto bg-white text-firefly text-center py-10 mb-auto">
            <div className="mx-auto max-w-[960px] px-8">
              {sections.map(({ image, title, heading, copy, link }, i) => {
                const theme = title.toLowerCase().includes("bespoke")
                  ? "theme-bespoke"
                  : "theme-commercial";
                return (
                  <div key={i} className={`${theme} space-y-8 mb-8`}>
                    {image && (
                      <Image
                        image={image}
                        className="w-full h-full bg-white mb-8 md:mb-16"
                      />
                    )}
                    <div
                      className={`text-skin-color text-2xl md:text-3xl uppercase tracking-[1em]`}
                    >
                      {title}
                    </div>
                    <div className="text-xl md:text-3xl font-bold uppercase tracking-wider">
                      {heading}
                    </div>
                    <div
                      className="text-stormdust tracking-wider space-y-4 font-light"
                      dangerouslySetInnerHTML={{ __html: copy }}
                    ></div>
                    {link && (
                      <LinkInternalBig url={link.uri} label="Find out more" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default group;
