import React from "react";
import { BsArrowRight } from "react-icons/bs";

const LinkInternalBig = ({ url, label }) => {
  return (
    <a
      href={url}
      className="text-2xl font-bold uppercase tracking-wide text-mineral inline-flex items-center hover:underline hover:opacity-70"
    >
      {label}
      <BsArrowRight className="text-4xl ml-4 text-firefly" />
    </a>
  );
};

export default LinkInternalBig;
